<template>
    <div>
        <div class="columns">
            <div class="column is-7 is-flex is-align-items-center">
                <h1 class="title column has-text-right orange-text">COVERED2GO IN PARTNERSHIP WITH</h1>
            </div>
            <div class="column">
                <picture>
                    <img src="../../assets/images/affiliates/inspired-logo-web.png" width="400px" />
                </picture>
            </div>
        </div>
        <div class="box b-shdw-3">
            <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>

            <div class="content">
                <h2>BOOKED YOUR TRIP? DON'T FORGET TO PACK YOUR TRAVEL INSURANCE.</h2>
                <p class="mb-4">
                    We are delighted to provide our customers with 5 Star Rated * Travel Insurance, in partnership with Covered2Go, whose policies have been designed to protect you from the moment you book your trip with us, right the way through to your arrival home!
                </p>
                <p>
                    Remember, it's important to have trusted travel insurance in place before you go.  All of our Platinum, Gold and Cruise policies have enhanced Covid cover for cancellation and emergency medical treatment.  This will help protect you and your holiday, so you can travel with confidence.
                </p>
                <h2>OUR TRAVEL INSURANCE COVER INCLUDES:</h2>
                <ul class="mb-4">
                    <li>Our Platinum, Gold and Cruise products now include <span class="orange-text">Covid-19 Cover</span>.</li>
                    <li>European and Worldwide cover available</li>
                    <li>Emergency medical expenses from £5m to £10m per person</li>
                    <li>Personal possessions cover is available from £1,500 to £5,000 per person</li>
                    <li>24 / 7 Emergency Medical Assistance</li>
                    <li>Cancellation Cover available up to £7,500 per person</li>
                    <li>If you require a higher Cancellation Cover (above £7,500 per person), please <a href="https://www.covered2go.co.uk/contact-us">Contact Us</a> directly for a quote.</li>
                </ul>

                <p class="mb-4">
                    Terms and Conditions Apply. Please see our <router-link :to="{ name: 'policyWordingsMUL' }">Policy Wording</router-link> for full details.
                </p>

                <h2>OPTIONAL EXTRAS AVAILABLE</h2>
                <ul class="mb-4">
                    <li>Cruise cover</li>
                    <li>Winter sports cover</li>
                    <li>Golf cover</li>
                    <li>Business cover</li>
                    <li>Sports and Activities</li>
                </ul>

            </div>

            <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
            <info-boxes/>
            <!-- <trustpilot-banner/> -->

        </div>
    </div>
</template>

<script>
    import InfoBoxes from "@/components/common/InfoBoxes";
    import InfoButtonOptions from "@/components/common/InfoButtonOptions";
    import { mapActions } from "vuex";

    export default {
        components: {
            InfoBoxes,
            InfoButtonOptions
        },
        methods: {
            ...mapActions({
                setAffiliation: "setAffiliation"
            })
        },
        created () {
            this.setAffiliation("inspiredluxuryescapes");
        }
    }
</script>

<style lang="scss" scoped>
    .orange-text {
        color: $c2g_orange;
    }

    .level-item {
        padding: 20px 0 40px 0;
        text-align: center;
    }
    .button {
        margin-right: 10px;
    }
    .card {
        margin-bottom: 15px;
    }
    .card-header-icon {
        min-width: 200px;
    }
    @media screen and (max-width: $mobile_width) {
        .card-header-icon {
            display: none;
        }
    }
    .card {
        margin-bottom: 10px;
    }

    // Content for injected content
    .content /deep/ {
        .faq-title {
            cursor: pointer;
        }

        h2 {
            color: $c2g_blue;
            font-weight: bold;
            font-size: 20px;
            margin: 15px 0 5px 0;
        }
        li {
            margin-left: 25px;
            margin-bottom: 10px;
            position: relative;
            list-style-type: none;
        }
        li:before {
            content: "";
            position: absolute;
            top: 3px;
            left: -25px;
            width: 20px;
            height: 20px;
            background-image: url('../../assets/images/icons/bullet-tick-sm.webp');
        }
        .card-header-icon::after {
            content: "Read more...";
        }
    }
</style>